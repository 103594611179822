import { mapGetters } from 'vuex'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import _ from 'lodash'

const stickyStateEnum = {
  // Not sticky (not scrolled past element)
  NOT_STICKY: 0,
  // Sticky (scrolled past element, but not past stickyAt)
  STICKY_NOT_READY: 1,
  // Sticky, but not active (scrolled past element and stickyAt, but scrolling down)
  STICKY_READY: 2,
  // Active, but not visible (scrolled past element and stickyAt, scrolling up, but not current sticky)
  STICKY_INVISIBLE: 3,
  // Visible (scrolled past element and stickyAt, scrolling up, current sticky)
  STICKY_VISIBLE: 4,
}

export default {
  mixins: [DetectMobileMixin],
  props: {
    enable: {
      type: Boolean,
      default: null,
    },
    // Sticky only inside an element
    restrictiveElementId: {
      type: String,
      default: null,
    },
    // Keep sticky el. visible on scroll down
    alwaysVisible: {
      type: Boolean,
      default: null,
    },
    alwaysVisibleMobile: {
      type: Boolean,
      default: null,
    },
    // Sticky from the beginning of element
    immediateSticky: {
      type: Boolean,
      default: false,
    },
    // Sticky after scroll over some distance ['vh', 'vh/x' where x is number (vh = viewpoint height), number or null]
    stickyAt: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      childrenPreventClose: false,
      elementBounding: null,
      holderBounding: null,
      holderSize: {
        width: null,
        height: 0,
      },
      inited: false,
      isDisabled: false,
      isResizing: false,
      isScrollingDown: true,
      local: {
        alwaysVisible:
          this.alwaysVisible !== null ? this.alwaysVisible : this.$themeSettings.components.Sticky.alwaysVisible,
        alwaysVisibleMobile:
          this.alwaysVisibleMobile !== null
            ? this.alwaysVisibleMobile
            : this.$themeSettings.components.Sticky.alwaysVisibleMobile,
        enable: this.enable !== null ? this.enable : this.$themeSettings.components.Sticky.enable,
      },
      preventTransition: true,
      restrictiveElement: null,
      restrictiveElementBounding: null,
      scrollY: 0,
      stickyAtCalculated: this.stickyAt && Number.isInteger(this.stickyAt) ? this.stickyAt : null,
      stickyId: null,
      stickyStateEnum,
    }
  },
  computed: {
    ...mapGetters({
      currentStickyIds: 'sticky/GET_CURRENT_ITEMS',
    }),
    elementStyle() {
      const style = {}
      if (this.holderSize.width && this.stickyState !== stickyStateEnum.NOT_STICKY) {
        style.width = `${this.holderSize.width}px`
      }
      return style
    },
    holderStyle() {
      const style = {}
      if (this.stickyState !== stickyStateEnum.NOT_STICKY && this.holderSize.height) {
        style.height = `${this.holderSize.height}px`
      }
      return style
    },
    isActive() {
      return (
        this.isSticky &&
        ((!this.isMobileDetected && this.local.alwaysVisible) ||
          (this.isMobileDetected && this.local.alwaysVisibleMobile) ||
          this.childrenPreventClose ||
          !this.isScrollingDown)
      )
    },
    isCurrent() {
      return this.currentStickyIds.includes(String(this.stickyId))
    },
    isPastElement() {
      return this.holderBounding && this.holderSize.height
        ? (this.immediateSticky ? 0 : this.holderSize.height) + this.holderBounding.top <= 0
        : false
    },
    isPastRestrictiveElement() {
      if (this.restrictiveElement) {
        return this.restrictiveElementBounding.height + this.restrictiveElementBounding.top - this.holderSize.height < 0
      }
      return false
    },
    isSticky() {
      return (
        this.isPastElement &&
        !this.isPastRestrictiveElement &&
        (this.stickyAtCalculated ? scrollY > this.stickyAtCalculated : true)
      )
    },
    isStickyActive() {
      return this.isActive && this.isSticky
    },
    stickyState() {
      if (!this.inited) {
        return stickyStateEnum.NOT_STICKY
      }
      let state
      if (this.isActive) {
        state = this.isCurrent ? stickyStateEnum.STICKY_VISIBLE : stickyStateEnum.STICKY_INVISIBLE
      } else if (this.isSticky) {
        state = stickyStateEnum.STICKY_READY
      } else if (this.isPastElement) {
        state = stickyStateEnum.STICKY_NOT_READY
      } else {
        state = stickyStateEnum.NOT_STICKY
      }
      return state
    },
  },
  watch: {
    disabled(value) {
      this.dispatchState()
    },
    scrollY(newValue, oldValue) {
      this.updateBoundings()
      this.isScrollingDown = newValue > oldValue
    },
    isSticky(value) {
      this.dispatchState()
    },
    isStickyActive: {
      immediate: true,
      handler(value) {
        this.$emit('isStickyActive', value)
      },
    },
    stickyState(newValue, oldValue) {
      this.preventTransition = oldValue === stickyStateEnum.NOT_STICKY
      this.updateMainClass(newValue)
    },
    childrenPreventClose(value) {
      if (!value) {
        this.isScrollingDown = false
      }
    },
  },
  mounted() {
    if (!this.local.enable) {
      return
    }
    this.throttleScroll = _.throttle(this.updateScrollY, 100)
    this.debounceResize = _.debounce(this.updateViewport, 100)
    this.inited = false
    window.addEventListener('scroll', this.throttleScroll)
    window.addEventListener('resize', this.debounceResize)
    this.$store.dispatch('sticky/ADD_ITEM')
    this.stickyId = this.$store.getters['sticky/GET_LAST_ID']
    this.restrictiveElement = document.getElementById(this.restrictiveElementId)
    this.dispatchState()
    setTimeout(() => {
      this.updateViewport()
      this.inited = true
    }, 1)
    this.updateMainClass(this.stickyState)
  },
  beforeDestroy() {
    if (!this.local.enable) {
      return
    }
    window.removeEventListener('scroll', this.throttleScroll)
    window.removeEventListener('resize', this.debounceResize)
    this.$store.dispatch('sticky/REMOVE_ITEM', this.stickyId)
  },
  methods: {
    dispatchState() {
      this.$store.dispatch('sticky/SET_ITEM_STATE', {
        id: this.stickyId,
        isSticky: this.isSticky,
        isDisabled: this.isDisabled,
      })
    },
    preventClose(state) {
      this.childrenPreventClose = state
    },
    updateMainClass(state) {
      this.$nuxt.$emit('mainClassStickyHeader', state)
    },
    updateBoundings() {
      if (this.$refs && this.$refs.holder && this.$refs.element) {
        this.holderBounding = this.$refs.holder.getBoundingClientRect()
        this.elementBounding = this.$refs.element.getBoundingClientRect()
        if (this.restrictiveElement) {
          this.restrictiveElementBounding = this.restrictiveElement.getBoundingClientRect()
        }
      }
    },
    updateScrollY() {
      this.scrollY = window.scrollY
    },
    updateViewport() {
      this.updateBoundings()
      this.holderSize.width =
        this.stickyState === stickyStateEnum.NOT_STICKY ? this.elementBounding.width : this.holderBounding.width
      this.holderSize.height =
        this.stickyState === stickyStateEnum.NOT_STICKY ? this.elementBounding.height : this.holderBounding.height
      this.isDisabled = this.holderSize.height === 0
      let stickyAt = this.stickyAt
      if (stickyAt && !Number.isInteger(stickyAt)) {
        if (stickyAt.includes('vh')) {
          stickyAt = stickyAt.replace('vh', window.innerHeight)
          stickyAt = eval(stickyAt)
        }
        this.stickyAtCalculated = stickyAt
      }
    },
  },
}
